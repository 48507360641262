import css from './sass/style.scss';

require('./js/polyfills.js');
require('./js/countup.js');
require('./js/fonts.js');
require('./js/glightbox.js');
require('./js/gsap.js');
require('./js/helpers.js');
require('./js/init.js');
require('./js/instagram.js');
require('./js/ismobile.js');
require('./js/nav.js');
require('./js/quiz.js');
require('./js/popup.js');
require('./js/scrollpos.js');

// Images

require('./img/ready/01.jpg');
require('./img/ready/01@2x.jpg');
require('./img/ready/02.jpg');
require('./img/ready/02@2x.jpg');

require('./img/reasons/bsmart.jpg');
require('./img/reasons/bsmart@2x.jpg');
require('./img/reasons/grander.jpg');
require('./img/reasons/grander@2x.jpg');
require('./img/reasons/pocket.jpg');
require('./img/reasons/pocket@2x.jpg');
require('./img/reasons/wings.jpg');
require('./img/reasons/wings@2x.jpg');

require('./img/about/01.jpg');
require('./img/about/01@2x.jpg');
require('./img/about/02.jpg');
require('./img/about/02@2x.jpg');
require('./img/team/01.jpg');
require('./img/team/01@2x.jpg');
require('./img/team/02.jpg');
require('./img/team/02@2x.jpg');
require('./img/top/01.jpg');
require('./img/top/01@2x.jpg');
require('./img/top/02.jpg');
require('./img/top/02@2x.jpg');
require('./img/top/03.jpg');
require('./img/top/03@2x.jpg');
require('./img/top/04.jpg');
require('./img/top/04@2x.jpg');

require('./img/video/01.jpg');
require('./img/video/01@2x.jpg');