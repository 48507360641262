import { gsap } from "gsap";
import { TweenLite, ScrollToPlugin, Power4 } from "gsap/all";
const scrollPlugin = ScrollToPlugin;
gsap.registerPlugin(ScrollToPlugin);

document.addEventListener('DOMContentLoaded',function() {
    	
	const quiz = document.getElementById('quiz');
	
    const init = function(el) {

		const quiz_item = quiz.getElementsByClassName('js-quizItem'),
			  quiz_results = document.getElementById('quiz-results'),
			  answers = document.getElementById('quiz-answers'),
			  message = answers.getElementsByClassName('message');

        for (let i = 0; i < quiz_item.length; i++) {
            
            const $$ = quiz_item[i],
				  index = cutme.Helpers.thisIndex( quiz_item[i] ),
				  answer = quiz_item[i].getElementsByClassName('o-radio');

            function changeEventHandler(event) {

                quiz_item[i].nextElementSibling.classList.add('is-active');
                            
                gsap.to(window, { duration: 1, scrollTo: { 
                    y: quiz_item[i].nextElementSibling, offsetY: document.getElementsByClassName('js-top')[0].clientHeight
                }, ease: Power4.easeOut });
                
                answers.getElementsByTagName('li')[index].getElementsByTagName('i')[0].classList.remove('icon-x', 'icon-check-oval');
                quiz_results.getElementsByClassName('answer--big')[0].classList.remove('icon-x', 'icon-check-oval');
                quiz_results.getElementsByClassName('message')[0].classList.remove('is-active');

                if ( event.target.dataset.answer ) {
                    answers.getElementsByTagName('li')[index].getElementsByTagName('i')[0].classList.add('icon-check-oval');
                    quiz_results.getElementsByClassName('answer--big')[0].classList.add('icon-check-oval');
				} else {
    				answers.getElementsByTagName('li')[index].getElementsByTagName('i')[0].classList.add('icon-x');
                    quiz_results.getElementsByClassName('answer--big')[0].classList.add('icon-x');
				}
				
				
				let good_answers = answers.getElementsByClassName('icon-check-oval');
				
				if( good_answers.length >= 3 ) {
    				quiz_results.getElementsByClassName('is-positive')[0].classList.add('is-active');					
				} else {
    				quiz_results.getElementsByClassName('is-negative')[0].classList.add('is-active');
				}
            };

            for (let j = 0; j<answer.length; j++) {
                answer[j].onchange = changeEventHandler;
            }

        }
/*

		$(quiz_item).each(function() {
		
			var $$ = $(this),
				index = $(this).index(),
				answer = $('.o-radio', this);
			
			answer.on('change', function() {
				
				$$.next().addClass('is-active');

				$('li', answers).eq(index).find('i').removeClass('icon-x icon-check-oval');
				$('.answer--big', quiz_results).removeClass('icon-x icon-check-oval');
				$('.message', quiz_results).removeClass('is-active');
				
				if ( $(this).data('answer') === true ) {
					$('li', answers).eq(index).find('i').addClass('icon-check-oval');
					$('.answer--big').addClass('icon-check-oval');
				} else {
					$('li', answers).eq(index).find('i').addClass('icon-x');
					$('.answer--big').addClass('icon-x');
				}
				
				
				if( $('.icon-check-oval', answers).size() >= 3 ) {
					$('.message.is-positive', quiz_results).addClass('is-active');
				} else {
					$('.message.is-negative', quiz_results).addClass('is-active');
				}
				
			});
		});
		
		
*/
	};

	quiz ? init() : false;
	

}, false);
