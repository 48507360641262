import InstagramFeed from "instafeed";
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

document.addEventListener('DOMContentLoaded',function() {
    
    window.instagram = function() {
        const el = document.getElementsByClassName('js-instagramSlider')[0];
    
        const init = function() {
            new InstagramFeed({
                'username': 'ekipakfc',
                'container': document.getElementsByClassName("js-instagram-feed")[0],
                'display_profile': false,
                'display_biography': false,
                'display_gallery': true,
                'display_igtv': false,
                'callback': function() {
                    const items = el.getElementsByTagName('a'),
                          parent = el.querySelector('.instagram_gallery')
                    
                    parent.outerHTML = parent.innerHTML
                    
                    for (let i = 0; i < items.length ; i++) {
                        items[i].classList.add('swiper-slide');
                    }
                    
                    setTimeout(function() {
                        const swiper = new Swiper(document.getElementsByClassName('js-instagramSlider')[0], {
                            freeMode: true,
                            spaceBetween: 0,
                            slidesPerView: 'auto',
                            speed: 800,
                            loop: true
                        });
                    }, 1000);

                    
                },
                'styling': false,
                'items': 10,
            });
        }
        
        el ? init() : false;
    }


}, false);
