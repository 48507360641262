import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {

    gsap.registerPlugin(ScrollTrigger);
    
    window.sections = function() {
        
        // Common

        gsap.utils.toArray(".js-fadeIn").forEach(function(section) {

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                },
                opacity: 0, 
                duration: 1,
                y: 50
            });
        });        
 
        gsap.utils.toArray(".js-fadeInChildren > *").forEach(function(section) {

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                },
                opacity: 0, 
                duration: 1,
                y: 50
            });
        });
        
        
        gsap.utils.toArray(".c-generations__list li p:first-of-type").forEach(function(section) {

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom',
                    end: 'top center',
                    scrub: 1
                },
                opacity: 0, 
                duration: 1,
                x: -150
            });
        });
        
        gsap.utils.toArray(".c-generations__list li p:last-of-type").forEach(function(section) {

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom',
                    end: 'top center',
                    scrub: 1.2
                },
                opacity: 0, 
                duration: 1,
                x: 150
            });
        });
        
        
        

        
        // Header
        
        //gsap.from('.c-header .o-cols__item:first-of-type', { opacity: 0, duration: 1, x: -50 })
        //gsap.from('.c-header .o-cols__item:last-of-type', { opacity: 0, duration: 1, x: 50 })
        
            
        // Benefits
        
        gsap.from('.c-process li:first-child', {
            scrollTrigger: { trigger: '.c-process li:first-child', start: 'center bottom' }, 
            opacity: 0, duration: 1, x: -50
        })
        
        gsap.from('.c-process li:nth-of-type(2)', {
            scrollTrigger: { trigger: '.c-process li:first-child', start: 'center bottom' }, 
            opacity: 0, duration: 1, x: -50, delay: .2
        })
        
        gsap.from('.c-process li:nth-of-type(3)', {
            scrollTrigger: { trigger: '.c-process li:first-child', start: 'center bottom' }, 
            opacity: 0, duration: 1, x: -50, delay: .4
        })
        
        gsap.from('.c-process li:nth-of-type(4)', {
            scrollTrigger: { trigger: '.c-process li:first-child', start: 'center bottom' }, 
            opacity: 0, duration: 1, x: -50, delay: .6
        })
        
        gsap.from('.c-process li:nth-of-type(5)', {
            scrollTrigger: { trigger: '.c-process li:first-child', start: 'center bottom' }, 
            opacity: 0, duration: 1, y: 50, delay: .8
        })
        
        
        // Ready cta
        
        gsap.from('.c-ready__cta', {
            scrollTrigger: { trigger: '.c-ready__cta', start: 'center bottom + 150' }, 
            opacity: 0, duration: 1, y: 50,  ease: 'Elastic.easeOut'
        })
        
        gsap.from('.c-ready .o-cols__item:last-of-type', {
            scrollTrigger: {
                trigger: '.c-ready .o-cols__item:last-of-type', 
                start: 'top bottom',
                end: 'center center',
                scrub: 1.2,
            }, 
            opacity: 0, duration: 1, y: 50,
        })
        
        
        
                
        // Team
        
        gsap.from('.c-team figure:last-of-type', {
            scrollTrigger: {
                trigger: '.c-team figure:last-of-type',
                start: 'top bottom',
                end: 'center center',
                scrub: 1.2
            },  
            opacity: 0,    
            y: -50,
        });
        
        gsap.from('.c-team figure:first-of-type', {
            scrollTrigger: {
                trigger: '.c-team figure:first-of-type',
                start: 'top bottom',
                end: 'center center',
                scrub: 1.2,
            },  
            opacity: 0,    
            y: 50,
        });
    };

    



}, false)
